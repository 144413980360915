import * as React from "react";
import { Link } from "gatsby";
import "./styles.scss";

export default function Banner({
    image,
    text,
    location,
    isOdd,
    newWindow,
    newClass
}) {
    return (
        <div>
            {location &&
                    <Link
                        to={location}
                        target={newWindow ? "_blank" : "_self"}>
                        <div className={`banner ${isOdd ? "odd" : "even"} ${newClass ? newClass : ''}`}>
                            <img
                                src={image}
                                alt={text}
                            />

                            <p className="text">
                                {text}
                            </p>
                        </div>
                    </Link>
            }

            {!location &&
                <div className={`banner ${isOdd ? "odd" : "even"} ${newClass ? newClass : ''}`}>
                        <img
                        src={image}
                        alt={text}
                    />

                    <p className="text">
                        {text}
                    </p>
                </div>
            }
        </div>
    );
}
