import React from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";

const Home = () => {
    return (
        <Layout title="Welcome">
            <MainTitle className={'home'}>
                Welcome to the<br/> MUBADALA SILICON
                <br/>  VALLEY CLASSIC 2022
            </MainTitle>

            <PageSummary>
                Celebrating the best in women's tennis!
            </PageSummary> 

            <ClaimRewardBtn />

            <Banner
                image="/images/silicon-valley/img-10.png"
                text="MUBADALA WELCOMES YOU"
                location="/mubadala-info/"
            />

            <Banner
                image="/images/silicon-valley/img-2.jpg"
                text="Daily Schedule"
                location="https://xela.co/s/svm-h-order_of_play"
                newWindow={true}
            />

            <Banner
                image="/images/silicon-valley/img-11.jpeg"
                text="Map"
                location="/map-preview"
                newWindow={true}
            />

            <Banner
                image="/images/silicon-valley/img-3.png"
                text="SKIP THE LINE "
                location="https://xela.co/s/svm-h-skip_the_line"
                newWindow={true}
            />

            <Banner
                image="/images/silicon-valley/img-5.jpg"
                text="ADDITIONAL TOURNAMENT INFO"
                newWindow={true}
                location="https://xela.co/s/svm-h-more_info"
            />

            <Banner
                image="/images/silicon-valley/img-7.jpeg"
                text="GIFTS AND OFFERS"
                newWindow={true}
                location="/event-promotions/"
            />

            <Banner
                image="/images/silicon-valley/img-6.jpg"
                text="Give Us Your Thoughts"
                newWindow={true}
                location="https://xela.co/s/svm-h-survey"
            />

            <ClaimRewardBtn footerButton={true}/>
        </Layout>
    );
};
export default Home;
