import * as React from "react";
import "./styles.scss";
import StyledLink from "../StyledLink/StyledLink";
import { useEffect, useState } from "react";
import Qr from "../../_utils/Qr";

export default function ClaimRewardBtn({ text, footerButton }) {
    const [showTbn, setShowTbn] = useState(true);
    const [goToRegistration, setGoToRegistration] = useState(true);

    useEffect(() => {
        if (!Qr.hasStoredParams() && !footerButton) {
            setShowTbn(false);
        }

        if (!Qr.hasStoredParams() && !!footerButton) {
            setGoToRegistration(false);
        }
    }, []);

    return showTbn ? (
        <StyledLink rewardsButton to={goToRegistration ? '/registration/' : 'https://xela.co/svmxxksx'}>
            {text ? text : "CLAIM Gift"}
        </StyledLink>
    ) : null;
}
