import axios from 'axios';
import Database from "../_utils/Database";

export function getGame(params) {
    console.log(process.env.GATSBY_GAME_LINK);
    return axios.get(`${process.env.GATSBY_FABACUS_API_URL}v1/game`, {
        params,
        headers: !!Database.getUserToken() && {
            "Authorization": `Bearer ${Database.getUserToken()}`,
        } || {}
    }).then((response) => {
        return !!response && response.data;
    }).catch((error) => {
        return error;
    })
}

export function endGame(params) {
    return axios.post(`${process.env.GATSBY_FABACUS_API_URL}v1/game/suggest`, params).then((response) => {
        return !!response && response.data;
    }).catch((error) => {
        return error;
    })
}