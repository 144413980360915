import Home from "./home";
import React, { useEffect, useState } from "react";
import Qr from "../_utils/Qr";
import Database from "../_utils/Database";
import { goto } from "../_utils/Misc";
import { getGame } from "../_services/game.service";

import moment from 'moment'
import jwtDecode from 'jwt-decode';

const Index = () => {
    let userData = !!Database.getUserToken() && jwtDecode(Database.getUserToken());
    useEffect(() => {
        Qr.removeGameParams();

        // Check if token has expired
        if(!!userData && userData.exp < moment(new Date()).unix()) {
            Database.removeUserToken();
        }

        // Check if game is available
        if (!!Qr.getQueryParams && (Qr.getQueryParams().game === 'true' || Qr.getQueryParams().game_suggest === 'true')) {
            getGamePayload(Qr.getQueryParams());
        } else {
            checkRedirection();
        }
    }, []);

    const getGamePayload = (storedParams) => {
        let params = {
            variant: storedParams.variant,
            campaign_id: storedParams.campaignId
        };

        return getGame(params).then(response => {
            !!response && !!response.levels ? Qr.storeGame(response) : Qr.storeGame(undefined);

            checkRedirection();
        })
    };

    const checkRedirection = () => {
        // Check for QR parameters
        if (Qr.hasQueryParams()) {

            // Save query data & redirect to reward page
            Qr.storeParams();

            // Check where to go
            if (Qr.getQueryParams().accessToken) {
                // Login
                goto("/login-fetch/");
            } else if (Database.getUserToken()) {
                !!Qr.getQueryParams && Qr.getQueryParams().game === 'true' && !!Qr.getGameParams()
                    ? goto("/taste-game/")
                    : !!Qr.getQueryParams && Qr.getQueryParams().game_suggest === 'true' && !!Qr.getGameParams()
                    ? goto("/suggest-menu/")
                    : goto("/qr-rewards-fetch/");
            } else {
                // If it`s not logged go to registration
                !!Qr.getQueryParams && Qr.getQueryParams().game === 'true' && !!Qr.getGameParams()
                ? goto("/taste-game/")
                : !!Qr.getQueryParams && Qr.getQueryParams().game_suggest === 'true' && !!Qr.getGameParams()
                ? goto("/suggest-menu/")
                : goto("/reward-lander/");
            }
        } else {
            // Redirect to home page
            goto("/home/");
        }
    };

    return Home;
};

export default Index;
